var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"pageSection"},[_c('SideBar'),_c('div',{staticClass:"pageContent pageContent--3 study"},[_c('div',{staticClass:"studyPageHeader"},[(_vm.isLoadingWeekPlanData)?_c('b-card',{staticStyle:{"width":"50%"}},[_c('div',{staticClass:"studyPageHeader__timerSection"},[_c('b-skeleton',{attrs:{"width":"80%"}}),_c('b-skeleton',{staticStyle:{"margin-left":"12px"},attrs:{"width":"40%","type":"input"}})],1),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"width":"60%"}})],1):_c('div',[_c('div',{staticClass:"studyPageHeader__timerSection"},[_c('p',{staticClass:"h4 studyPageHeader__timerSection_title"},[_vm._v(" "+_vm._s(this.weekPlanTitleString)+" ")]),(
                                _vm.$store.getters.getWeekPlan &&
                                _vm.$store.getters.getWeekPlan.currentBlock &&
                                _vm.$store.getters.getWeekPlan.currentBlock.openDate
                                    ? _vm.$store.getters.getWeekPlan.currentBlock.openDate
                                    : _vm.$store.getters.getWeekPlan &&
                                      _vm.$store.getters.getWeekPlan.nextBlock &&
                                      _vm.$store.getters.getWeekPlan.nextBlock.openDate
                                    ? _vm.$store.getters.getWeekPlan.nextBlock.openDate
                                    : false
                            )?_c('TextTimer',{style:(this.timerStyle),attrs:{"time":_vm.$store.getters.getWeekPlan &&
                                _vm.$store.getters.getWeekPlan.currentBlock &&
                                _vm.$store.getters.getWeekPlan.currentBlock.openDate
                                    ? _vm.$store.getters.getWeekPlan.currentBlock.openDate
                                    : _vm.$store.getters.getWeekPlan.nextBlock.openDate}}):_vm._e()],1),_c('p',{staticClass:"text2 colorFiord"},[_vm._v(" "+_vm._s(this.weekPlanDescriptionString)+" ")])]),_c('div',{staticClass:"studyPageHeader__buttonSection"},[(_vm.isLoadingWeekPlanData)?_c('b-skeleton',{attrs:{"width":"44px","height":"44px","type":"button"}}):_c('button',{staticClass:"studyPageHeader__schedule",on:{"click":function () { return _vm.onOpenModal(); }}},[_c('img',{staticClass:"imgBlock",attrs:{"alt":"square","src":require("@/assets/img/pages/study/square.svg")}})]),(_vm.isVisibleModal)?_c('StudyCalendar',{attrs:{"onClose":function () { return _vm.onCloseModal(); }}}):_vm._e()],1)],1),_c('div',{staticClass:"pageTitle pageTitleTimer"},[_c('p',{staticClass:"pageTitleTimer__title"},[_vm._v(" Блоки обучения ")]),(_vm.courseEnded)?_c('div',{staticClass:"courseEndedText courseEndedBox"},[_c('div',{staticClass:"courseEnded"},[_c('p',{staticClass:"h4"},[_vm._v("До конца обучения:")]),_c('p',{staticClass:"courseEndedTime title1 title1--2"},[_c('b',[_vm._v(_vm._s(_vm.courseEnded))]),(_vm.courseEnded === 1)?_c('span',[_vm._v(" день")]):(_vm.courseEnded > 1 && _vm.courseEnded < 5)?_c('span',[_vm._v(" дня")]):_c('span',[_vm._v(" дней")])])]),(_vm.isVisibleModalInfoProtector)?_c('StudyInfoProtector',{attrs:{"onClose":function () { return _vm.onCloseModalInfoProtector(); }}}):_vm._e()],1):_vm._e()]),_c('div',[(
                        _vm.$store.getters.getUserProgress &&
                            _vm.$store.getters.getUserProgress.progress &&
                            _vm.$store.getters.getUserProgress.progress.data &&
                            _vm.$store.getters.getUserProgress.additionalProgress &&
                            _vm.$store.getters.getUserProgress.additionalProgress.data
                    )?_c('div',{staticClass:"studyContentHeader"},[_c('div',{staticClass:"studyButtons"},[_c('Button',{staticClass:"studyButtons__button",attrs:{"extraClass":_vm.main
                                    ? "button--small"
                                    : "button--small button--smallWhite","onClick":function () { return _vm.handleClickRadioButton('MAIN'); },"title":"Основные"}}),_c('Button',{staticClass:"studyButtons__button",attrs:{"extraClass":_vm.additional
                                    ? "button--small"
                                    : "button--small button--smallWhite","onClick":function () { return _vm.handleClickRadioButton('ADDITIONAL'); },"title":"Дополнительные"}})],1)]):_vm._e(),(
                        _vm.$store.getters.getUserProgress &&
                            _vm.$store.getters.getUserProgress.progress &&
                            _vm.$store.getters.getUserProgress.progress.data &&
                            _vm.$store.getters.getUserProgress.progress.data[0] &&
                            _vm.$store.getters.getUserProgress.progress.data[0].blocks
                    )?_c('div',{class:_vm.radioButton ? 'hidden' : ''},[(
                            _vm.isVisibleModalStudyOnboarding ||
                                this.$store.getters.getIsReviewOnboardStudy
                                    .isReviewOnboardStudy
                        )?_c('div',{staticClass:"popup-layout"}):_vm._e(),(
                            _vm.isVisibleModalStudyOnboarding ||
                                this.$store.getters.getIsReviewOnboardStudy
                                    .isReviewOnboardStudy
                        )?_c('OnboardModal',{staticStyle:{"left":"288px","top":"376px"},attrs:{"currentStep":"1","totalSteps":"1","title":"Открытие блоков","description":"Основне блоки с уроками открываются каждую пятницу в 18:00 по МСК (UTC+3).\n                        Если видео не открывается, зайдите на урок несколько раз. ","isNextButton":true,"nextButtonTitle":"ЗАВЕРШИТЬ","onClose":function () { return _vm.showedStudyOnboarding(); },"onCloseModal":function () { return _vm.showedStudyOnboarding(); }}}):_vm._e(),_c('div',{class:(" " + (_vm.isVisibleModalStudyOnboarding ||
                                this.$store.getters.getIsReviewOnboardStudy
                                    .isReviewOnboardStudy
                                    ? 'studyBlockOnboarding'
                                    : ''))},[(_vm.isLoadingStudyData)?_c('b-card',[_c('b-skeleton',{attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}}),_c('b-skeleton',{staticStyle:{"margin-top":"10px"},attrs:{"height":"96px"}})],1):_vm._l((_vm.$store.getters.getUserProgress
                                .progress.data[0].blocks),function(item,index){return _c('div',{key:item.block_number,staticClass:"study__contentItem"},[_c('StudyCard',{attrs:{"hasBlockAccess":item.hasAccesByAccesLevel,"isLocked":!item.opened,"studyCardLessons":item.lessons,"studyCardNumber":item.blockPriority,"studyCardTitle":item.blockTitle,"studyCourse":_vm.member.slug,"studyFinishedLessons":item.lessons.filter(function (i) { return i.isPassed; }).length,"studyIcon":require("@/assets/img/pages/study/arrowRight.svg"),"studyTotalLessons":item.blockCountLessons,"lockedBlockText":index === 0 &&
                                    _vm.$store.getters.getUserProgress.progress.data[0]
                                        .blocks[0] &&
                                    !_vm.$store.getters.getUserProgress.progress.data[0]
                                        .blocks[0].opened
                                        ? 'Этот блок обучения пока не доступен Вам. Он будет доступен в ближайшую пятницу в 12:00 по МСК (UTC+3).'
                                        : 'Этот блок обучения пока недоступен Вам. Он будет доступен в ближайшую пятницу в 18:00 по МСК (UTC+3) после просмотра всех видео-уроков и прохождения заданий предыдущего блока обучения.',"member":_vm.member}})],1)})],2)],1):_vm._e()]),(
                    _vm.$store.getters.getUserProgress &&
                        _vm.$store.getters.getUserProgress.additionalProgress &&
                        _vm.$store.getters.getUserProgress.additionalProgress.data &&
                        _vm.$store.getters.getUserProgress.additionalProgress.data[0] &&
                        _vm.$store.getters.getUserProgress.additionalProgress.data[0]
                            .blocks
                )?_c('div',{class:_vm.radioButton ? '' : 'hidden'},_vm._l((_vm.$store.getters.getUserProgress
                        .additionalProgress.data[0].blocks),function(item,index){return _c('div',{key:item.block_number,staticClass:"study__contentItem"},[_c('StudyCard',{attrs:{"isLocked":!item.hasAccesByAccesLevel,"studyCardLessons":item.lessons,"studyCardNumber":item.blockPriority,"studyCardTitle":item.blockTitle,"studyCourse":_vm.member.slug,"studyFinishedLessons":item.blockProgress,"studyIcon":require("@/assets/img/pages/study/arrowRight.svg"),"studyTotalLessons":item.blockCountLessons,"lockedBlockText":!item.opened &&
                            _vm.$store.getters.getUserProgress.additionalProgress.data[0]
                                .blocks[index - 1].opened
                                ? 'Этот блок обучения пока недоступен Вам. Он будет доступен в ближайшую пятницу в 18:00 по МСК (UTC+3).'
                                : 'Этот блок обучения пока недоступен Вам. Он будет доступен после просмотра видео-уроков и прохождения заданий предыдущего блока обучения.',"member":_vm.member}})],1)}),0):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }