var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layout popup-layout--2"},[_c('div',{staticClass:"popup popup--5 studyCalendar"},[_c('button',{staticClass:"popup__close",attrs:{"type":"button"},on:{"click":_vm.onClose}}),_vm._m(0),_c('div',{staticClass:"studyCalendarCurrent"},[(_vm.weekPlanTitleString)?_c('p',{staticClass:"title1 studyCalendarCurrent__title1"},[_vm._v(" "+_vm._s(_vm.weekPlanTitleString)+" ")]):_vm._e(),(_vm.weekPlanDescriptionString)?_c('p',{staticClass:"text2 studyCalendarCurrent__title2"},[_vm._v(" "+_vm._s(_vm.weekPlanDescriptionString)+" ")]):_vm._e(),(
                    _vm.$store.getters.getWeekPlan &&
                    _vm.$store.getters.getWeekPlan.currentBlock &&
                    _vm.$store.getters.getWeekPlan.currentBlock.openDate
                        ? _vm.$store.getters.getWeekPlan.currentBlock.openDate
                        : _vm.$store.getters.getWeekPlan &&
                          _vm.$store.getters.getWeekPlan.nextBlock &&
                          _vm.$store.getters.getWeekPlan.nextBlock.openDate
                        ? _vm.$store.getters.getWeekPlan.nextBlock.openDate
                        : false
                )?_c('TextTimer',{style:(this.timerStyle),attrs:{"time":_vm.$store.getters.getWeekPlan &&
                    _vm.$store.getters.getWeekPlan.currentBlock &&
                    _vm.$store.getters.getWeekPlan.currentBlock.openDate
                        ? _vm.$store.getters.getWeekPlan.currentBlock.openDate
                        : _vm.$store.getters.getWeekPlan.nextBlock.openDate}}):_vm._e()],1),_c('div',{staticClass:"customScroll studyCalendarMain"},[_c('ScheduleCalendar',{attrs:{"data":_vm.scheduleCalendarData}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"studyCalendarHeader"},[_c('p',{staticClass:"h3 studyCalendarTitle"},[_vm._v("Недельный план")])])}]

export { render, staticRenderFns }