<template>
    <div class="scheduleCalendar">
        <div v-for="item in data" :key="item.data" class="scheduleCalendarItem">
            <div class="scheduleCalendarItemDates">
                <div
                    v-if="day.day && day.date"
                    v-for="day in item.days"
                    :key="day"
                    :class="
                        day.isActive
                            ? 'scheduleCalendarItemDates__item scheduleCalendarItemDates__item--active'
                            : 'scheduleCalendarItemDates__item'
                    "
                >
                    <p
                        v-if="item.days[0].date === day.date"
                        class="h5 dateTitleTop colorYellow"
                    >
                        Старт блока
                    </p>
                    <p class="title1 scheduleCalendarItemDates__item_day">
                        {{ day.day }}
                    </p>
                    <p class="title1 scheduleCalendarItemDates__item_date">
                        {{ day.date }}
                    </p>
                    <p
                        v-if="item.days[item.days.length - 1].date === day.date"
                        class="h5 dateTitleBottom colorGreen"
                    >
                        Конец блока
                    </p>
                </div>
            </div>

            <div class="scheduleCalendarItemContent">
                <p
                    v-if="item.pretitle"
                    class="title1 scheduleCalendarItemContent__pretitle"
                >
                    {{ item.pretitle }}
                </p>
                <div class="scheduleCalendarItemBox">
                    <header class="scheduleCalendarItemBox__header">
                        <p v-if="item.title" class="scheduleCalendarItemBox__title">
                            <span class="h4"
                                >Блок {{ item.priority }} {{ item.title }}</span
                            >
                        </p>
                    </header>
                    <p
                        v-if="item.text"
                        class="text2 colorGray scheduleCalendarItemBox__text"
                    >
                        {{ item.text }}
                    </p>

                    <div class="scheduleCalendarSublist">
                        <div
                            v-for="lesson in item.lessons"
                            :key="lesson"
                            class="scheduleCalendarItemBox scheduleCalendarItemBox--2 scheduleCalendarSublist__item"
                        >
                            <header class="scheduleCalendarItemBox__header">
                                <p
                                    v-if="lesson.lesson.title"
                                    class="scheduleCalendarItemBox__title"
                                >
                                    <span class="text2">{{ lesson.lesson.title }}</span>
                                    <VipUpsellButton
                                        v-if="lesson.isSpecial"
                                        :tooltipDirection="
                                            lesson.isSpecialRight ? 'right' : ''
                                        "
                                    />
                                </p>
                                <p
                                    v-if="lesson.isLessonPassed"
                                    class="title1 scheduleCalendarItemBox__status scheduleCalendarItemBox__status--success"
                                >
                                    Сделано
                                    <img
                                        class="scheduleCalendarItemBox__status_img"
                                        alt="check-success"
                                        src="@/assets/img/common/iconCheckSuccess.svg"
                                    />
                                </p>
                                <!--<p v-if="!lesson.isLessonPassed" class="title1 scheduleCalendarItemBox__status scheduleCalendarItemBox__status&#45;&#45;fail">
                                    Не сделано
                                </p>-->
                            </header>
                            <p
                                v-if="
                                    lesson.openData &&
                                        lesson.openData.status &&
                                        lesson.openData.status === 'close'
                                "
                                class="h5 colorGray scheduleCalendarItemBox__text"
                            >
                                {{ lesson.openData.text }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";

export default {
    name: "ScheduleCalendar",

    components: {
        VipUpsellButton,
    },

    props: {
        data: {
            days: [
                {
                    day: String,
                    date: String,
                    isActive: Boolean,
                },
            ],
            lessons: [
                {
                    lesson: Object,
                    isSpecial: Boolean,
                    isSpecialRight: Boolean,
                },
            ],
            pretitle: String,
            title: String,
            text: String,
        },
    },
};
</script>

<style lang="scss">
@import "./scheduleCalendar.scss";
</style>
