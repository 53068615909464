<template>
    <div :class="isLocked ? 'studyCard studyCard--locked' : 'studyCard'">
        <!-- Card Content -->
        <div
            @mouseenter="() => mouseIn()"
            @mouseleave="() => mouseOut()"
            @click="isCardContent = !isCardContent"
            :class="
                `studyCardHeader ${(isCardContent && !isLocked && 'BottomPad') || ''}`
            "
        >
            <!-- Card Header -->
            <div class="studyCardHeader__leftContentSide">
                <p
                    :class="
                        `studyCardContent__textCardNumber
                            ${isLocked ? 'h4 colorHeather' : 'h4 colorGray'}`
                    "
                >
                    {{ "Блок " + studyCardNumber }}
                </p>
                <p
                    :class="
                        `studyCardContent__textCardTitle
                            ${isLocked ? 'h2 colorGray' : 'h2'}`
                    "
                >
                    {{ studyCardTitle }}
                    &nbsp;
                    <VipUpsellButton v-if="!hasBlockAccess" />
                </p>
            </div>
            <div class="studyCardHeader__rightContentSideCardProgress">
                <p
                    v-if="studyFinishedLessons === studyTotalLessons"
                    class="text colorGray studyCardHeader__text"
                >
                    Пройден
                </p>
                <p v-else-if="studyTotalLessons" class="text studyCardHeader__text">
                    Пройдено уроков {{ studyFinishedLessons }}/{{ studyTotalLessons }}
                </p>
                <p v-if="isLocked">
                    <Tooltip
                        class="tooltipIcon"
                        :tooltipIcon="require('@/assets/img/pages/study/lock.svg')"
                        :text="
                            lockedBlockText || 'Этот блок обучения пока недоступен Вам.'
                        "
                    />
                </p>
                <img
                    :class="`studyCardImg ${isLocked ? 'hidden' : ''}`"
                    v-bind:src="
                        isLocked
                            ? lockIcon
                            : studyFinishedLessons === studyTotalLessons && !isInside
                            ? doneIcon
                            : !isLocked && isInside && !isCardContent
                            ? arrowDown
                            : studyIcon && isCardContent
                            ? arrowUp
                            : !isCardContent && studyIcon
                    "
                    alt=""
                />
            </div>
        </div>
        <div class="studyCardContent" v-if="isCardContent && !isLocked">
            <div class="studyCardContent__items">
                <div
                    v-for="item in studyCardLessons"
                    :key="item.lesson._id"
                    @mouseenter="() => mouseInItem(item.id)"
                    @mouseleave="() => mouseOutItem()"
                    class="studyCardContent__itemText"
                >
                    <div
                        class="studyCardHeader__leftContentSide"
                        @click="
                            () =>
                                onRedirectTo(
                                    item.hasAccesToLesson && item.hasAccesByAccesLevel
                                        ? !item.lesson.videos.filter(
                                              (v) =>
                                                  v.accessLevels.indexOf(
                                                      member.memberAccessLevel__id
                                                  ) >= 0
                                          ).length
                                            ? item.lesson.type === 'algorithm'
                                                ? `/${studyCourse}/study/${studyCardNumber}/${item.lesson.lesson_id}/algorithm`
                                                : `/${studyCourse}/study/${studyCardNumber}/${item.lesson.lesson_id}/homework`
                                            : `/${studyCourse}/study/${studyCardNumber}/${item.lesson.lesson_id}`
                                        : ``
                                )
                        "
                    >
                        <p
                            v-if="
                                item.lesson.lesson_number && item.lesson.type === 'common'
                            "
                            :class="
                                `studyCardContent__block
                                ${
                                    !item.hasAccesToLesson
                                        ? 'text2 colorBotticelli'
                                        : 'text2 colorLynch'
                                }`
                            "
                        >
                            {{ "Урок " + item.lesson.lesson_number }}
                        </p>
                        <div
                            v-if="item.lesson.title"
                            :class="
                                `studyCardContent__text
                                ${!item.hasAccesToLesson ? 'text colorHeather' : 'text'}`
                            "
                        >
                            {{ item.lesson.title }}&nbsp;
                            <div class="vipButton">
                                <VipUpsellButton v-if="!item.hasAccesByAccesLevel" />
                            </div>
                        </div>
                        <div
                            class="studyCardHeader__rightContentSide"
                            v-if="item.hasAccesToLesson"
                        >
                            <img
                                v-if="!item.hasAccesToLesson"
                                class="studyCardImg"
                                v-bind:src="lockIcon"
                                alt="lock"
                            />
                            <img
                                v-else-if="item.isPassed"
                                class="studyCardImg"
                                v-bind:src="doneIcon"
                                alt="done"
                            />
                            <img
                                v-else
                                class="studyCardImg"
                                v-bind:src="arrowRight"
                                alt="arrow-right"
                            />
                        </div>
                    </div>
                    <p v-if="!item.hasAccesToLesson">
                        <Tooltip
                            v-if="item.lesson.type === 'additional'"
                            class="tooltipIcon"
                            :tooltipIcon="require('@/assets/img/pages/study/lock.svg')"
                            text="Дополнительное задание откроется Вам в среду, ровно в 18:00 по МСК (UTC+3) при условии просмотра всех уроков этого блока и выполнения всех домашних заданий."
                        />
                        <Tooltip
                            v-else
                            class="tooltipIcon"
                            :tooltipIcon="require('@/assets/img/pages/study/lock.svg')"
                            text="Этот урок станет для Вас открытым только после просмотра предыдущего и прохождения задания, если оно предусмотрено в предыдущем уроке."
                        />
                    </p>
                </div>
            </div>
            <div class="h4 colorFiord studyCardContent__itemsFooter">
                <p>
                    Обсудите этот блок обучения с другими студентами&nbsp;
                    <VipUpsellButton v-if="!canAccessChat" />
                </p>
                <Button
                    v-if="canAccessChat"
                    extraClass="button--white"
                    title="Комментировать"
                    :isLink="true"
                    :link="`/${$route.params.slug}/study/${studyCardNumber}/comments`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";
import Tooltip from "@/views/components/Tooltip/Tooltip";

import DoneIcon from "../../../assets/img/pages/study/done.svg";
import LockIcon from "../../../assets/img/pages/study/lock.svg";
import ArrowDown from "../../../assets/img/pages/study/arrowDown.svg";
import ArrowUp from "../../../assets/img/pages/study/arrowUp.svg";
import ArrowRight from "../../../assets/img/pages/study/arrowRight.svg";

export default {
    name: "studyCard",
    components: {
        Button,
        VipUpsellButton,
        Tooltip,
    },

    props: {
        hasBlockAccess: Boolean,
        studyCourse: String,
        studyCardNumber: Number,
        studyCardTitle: String,
        studyFinishedLessons: Number,
        studyTotalLessons: Number,
        studyIcon: String,
        isLocked: Boolean,
        studyCardLessons: Array,
        isVip: Boolean,
        lockedBlockText: String,
        member: Object,
    },

    computed: {
        canAccessChat: function() {
            return (
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.chat &&
                (this.$store.getters.getMember.data.memberAccessLevel === "ВИП 3.0" ||
                    this.$store.getters.getMember.data.memberAccessLevel === "VIP")
            );
        },
    },

    data() {
        return {
            isCardContent: false,
            isInside: false,
            isInsideItem: false,
            selectedId: null,
            selectedRoute: null,

            lockIcon: LockIcon,
            arrowDown: ArrowDown,
            arrowUp: ArrowUp,
            arrowRight: ArrowRight,
            doneIcon: DoneIcon,
        };
    },

    methods: {
        mouseIn() {
            this.isInside = true;
        },
        mouseOut() {
            this.isInside = false;
        },

        onRedirectTo(route) {
            if (route) this.$router.push(route);
        },

        mouseInItem(id) {
            this.selectedId = id;
            this.isInsideItem = true;
        },
        mouseOutItem() {
            this.isInsideItem = false;
        },
    },
};
</script>

<style lang="scss">
@import "./studyCard.scss";
</style>
