<template>
    <div class="popup-layout popup-layout--2">
        <div class="popup popup--5 studyCalendar">
            <button class="popup__close" type="button" @click="onClose"></button>

            <div class="studyCalendarHeader">
                <p class="h3 studyCalendarTitle">Недельный план</p>
            </div>

            <div class="studyCalendarCurrent">
                <p v-if="weekPlanTitleString" class="title1 studyCalendarCurrent__title1">
                    {{ weekPlanTitleString }}
                </p>
                <p
                    v-if="weekPlanDescriptionString"
                    class="text2 studyCalendarCurrent__title2"
                >
                    {{ weekPlanDescriptionString }}
                </p>
                <TextTimer
                    v-if="
                        $store.getters.getWeekPlan &&
                        $store.getters.getWeekPlan.currentBlock &&
                        $store.getters.getWeekPlan.currentBlock.openDate
                            ? $store.getters.getWeekPlan.currentBlock.openDate
                            : $store.getters.getWeekPlan &&
                              $store.getters.getWeekPlan.nextBlock &&
                              $store.getters.getWeekPlan.nextBlock.openDate
                            ? $store.getters.getWeekPlan.nextBlock.openDate
                            : false
                    "
                    :style="this.timerStyle"
                    :time="
                        $store.getters.getWeekPlan &&
                        $store.getters.getWeekPlan.currentBlock &&
                        $store.getters.getWeekPlan.currentBlock.openDate
                            ? $store.getters.getWeekPlan.currentBlock.openDate
                            : $store.getters.getWeekPlan.nextBlock.openDate
                    "
                />
            </div>

            <div class="customScroll studyCalendarMain">
                <ScheduleCalendar :data="scheduleCalendarData"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import TextTimer from "@/views/components/TextTimer/TextTimer";
import ScheduleCalendar from "@/views/components/ScheduleCalendar/ScheduleCalendar";
import Alert from "@/views/partials/Alert/Alert";
import axios from "axios";

export default {
    name: "studyCalendar",

    components: {
        Button,
        TextTimer,
        ScheduleCalendar,
        Alert,
    },

    props: ["onClose"],

    async mounted() {
        const $this = this;
        await $this.updateWeekPlanData().then(async (updateWeekPlanDataResult) => {
            if (updateWeekPlanDataResult) {
                await $this.renderWeekPlan();
            } else {
                await $this.$store.dispatch("clearWeekPlan").then(async () => {
                    await $this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при загрузке недельного плана",
                        },
                    });
                });
            }
        });
    },

    data() {
        return {
            displayNextBlock: true,
            scheduleCalendarData: [],
            weekPlanTitleString: "",
            weekPlanDescriptionString: "",
            timerStyle: "display: block;",
        };
    },

    methods: {
        onCloseModal() {
            this.onClose();
        },

        updateWeekPlanData: async function () {
            const getWeekPlanDataResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/week_plan"
            );
            if (
                getWeekPlanDataResult &&
                getWeekPlanDataResult.data &&
                getWeekPlanDataResult.data.result
            ) {
                return await this.$store
                    .dispatch("setWeekPlan", getWeekPlanDataResult.data)
                    .then(() => {
                        return true;
                    });
            } else {
                return false;
            }
        },

        renderWeekPlan: function () {
            const weekPlan = this.$store.getters.getWeekPlan;

            const currentBlockOpenDate =
                weekPlan && weekPlan.currentBlock && weekPlan.currentBlock.openDate
                    ? new Date(weekPlan.currentBlock.openDate)
                    : null;
            const nextBlockOpenDate =
                weekPlan && weekPlan.nextBlock && weekPlan.nextBlock.openDate
                    ? new Date(weekPlan.nextBlock.openDate)
                    : null;
            const nextFriday = this.getNextFridayDate();

            if (currentBlockOpenDate) {
                this.displayNextBlock = false;
            }

            this.scheduleCalendarData = [];

            let descriptionCurrentBlockString = `${(weekPlan &&
                weekPlan.currentBlock &&
                weekPlan.currentBlock.description) ||
            ""} `;
            let openDateCurrentBlockString = ``;
            if (currentBlockOpenDate && currentBlockOpenDate > new Date()) {
                openDateCurrentBlockString = `Блок ${
                    weekPlan.currentBlock.priority
                } будет открыт в ${currentBlockOpenDate.getHours()}:${(currentBlockOpenDate.getMinutes() <
                10
                    ? "0"
                    : "") + currentBlockOpenDate.getMinutes()}`;
            }
            this.scheduleCalendarData.push({
                days: [],
                pretitle:
                    (weekPlan &&
                        weekPlan.currentBlock &&
                        weekPlan.currentBlock.lessonsNum) ||
                    "",
                title:
                    (weekPlan && weekPlan.currentBlock && weekPlan.currentBlock.title) ||
                    "",
                priority:
                    (weekPlan &&
                        weekPlan.currentBlock &&
                        weekPlan.currentBlock.priority) ||
                    "",
                text: descriptionCurrentBlockString + openDateCurrentBlockString,
            });

            this.scheduleCalendarData[0].days = this.calculateDaysBlocks(2, currentBlockOpenDate, nextBlockOpenDate || nextFriday, "first", weekPlan.memberStartedDate);
            this.scheduleCalendarData[0].lessons = weekPlan.currentBlock.lessons;

            if (this.displayNextBlock) {
                if (weekPlan.nextBlock && weekPlan.nextBlock._id) {
                    let descriptionNextBlockString = `${weekPlan.nextBlock.description}\n`;
                    let openDateNextBlockString = ``;
                    if (nextBlockOpenDate && nextBlockOpenDate > new Date()) {
                        openDateNextBlockString = `Блок ${weekPlan.nextBlock.priority} будет открыт в ${nextBlockOpenDate.getHours()}:${(nextBlockOpenDate.getMinutes() < 10 ? '0' : '') + nextBlockOpenDate.getMinutes()}`;
                    }
                    this.scheduleCalendarData.push({
                        days: [],
                        pretitle: `${weekPlan.nextBlock.lessonsNum}`,
                        title: weekPlan.nextBlock.title,
                        priority: weekPlan.nextBlock.priority,
                        text: descriptionNextBlockString + openDateNextBlockString
                    });

                    this.scheduleCalendarData[1].days = this.calculateDaysBlocks(1, currentBlockOpenDate, nextBlockOpenDate, 'second', weekPlan.memberStartedDate);
                    this.scheduleCalendarData[1].lessons = weekPlan.nextBlock.lessons;
                } else {
                    if (weekPlan.currentBlockByDate._id !== weekPlan.currentBlock._id) {
                        this.scheduleCalendarData.push({
                            days: [],
                            pretitle: `${weekPlan.currentBlockByDate.lessonsNum}`,
                            title: weekPlan.currentBlockByDate.title,
                            priority: weekPlan.currentBlockByDate.priority,
                            text: ``
                        });

                        this.scheduleCalendarData[1].days = this.calculateDaysBlocks(1, currentBlockOpenDate, nextFriday, 'second', weekPlan.memberStartedDate);
                        this.scheduleCalendarData[1].lessons = weekPlan.currentBlockByDate.lessons;
                    }
                }
            }

            if (currentBlockOpenDate) {
                if (
                    weekPlan &&
                    weekPlan.currentBlock &&
                    weekPlan.currentBlock.description
                ) {
                    this.weekPlanTitleString = `${weekPlan.currentBlock.description}. `;
                }
                if (
                    weekPlan &&
                    weekPlan.currentBlock &&
                    weekPlan.currentBlock.priority &&
                    weekPlan.currentBlock.title
                ) {
                    this.weekPlanTitleString += `Блок ${weekPlan.currentBlock.priority} ${weekPlan.currentBlock.title} будет открыт через `;
                }
                this.timerStyle = "display: block;";
            } else {
                if (weekPlan.nextBlock && weekPlan.nextBlock._id) {
                    this.weekPlanTitleString = `Следующий блок ${weekPlan.nextBlock.priority} ${weekPlan.nextBlock.title}`;
                }
                if (
                    weekPlan &&
                    weekPlan.currentBlock &&
                    weekPlan.currentBlock.priority &&
                    weekPlan.currentLesson &&
                    weekPlan.currentLesson.lesson &&
                    weekPlan.currentLesson.lesson.title
                ) {
                    this.weekPlanDescriptionString = `Текущее задание: Блок ${weekPlan.currentBlock.priority}. Урок ${weekPlan.currentLesson.priority}. ${weekPlan.currentLesson.lesson.title}`;
                }
                this.timerStyle = "display: block;";
            }
        },

        calculateDaysBlocks(days, currentBlockOpenDate, nextBlockOpenDate, block, memberStartedDate) {
            const daysArray = [];

            const now = new Date();

            for (let i = 0; i < days; i++) {
                daysArray.push({
                    day: "",
                    date: "",
                    isActive: "",
                });
            }

            const d1 = currentBlockOpenDate ? new Date(currentBlockOpenDate) : null;
            const d2 = nextBlockOpenDate ? new Date(nextBlockOpenDate) : null;
            const d3 = memberStartedDate ? new Date(memberStartedDate) : null;

            if (block === "first") {
                if (d1) {
                    daysArray[0] = {
                        day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(new Date(d1)),
                        date: d1.getDate() + "." + (d1.getMonth() + 1),
                        isActive: d1.getDate() + "." + (d1.getMonth() + 1) === now.getDate() + "." + (now.getMonth() + 1),
                    };
                } else {
                    const t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
                    const lastFriday = new Date();
                    lastFriday.setDate(t);

                    if (lastFriday > d3) {
                        daysArray[0] = {
                            day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(lastFriday),
                            date: lastFriday.getDate() + "." + (lastFriday.getMonth() + 1),
                            isActive: lastFriday.getDate() + "." + (lastFriday.getMonth() + 1) === now.getDate() + "." + (now.getMonth() + 1),
                        };
                    } else {
                        daysArray[0] = {};
                    }

                    if (daysArray[0].isActive) {
                        daysArray[1] = {
                            day: "",
                            date: "",
                            isActive: false,
                        };
                    } else {
                        daysArray[1] = {
                            day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(new Date(now)),
                            date: now.getDate() + "." + (now.getMonth() + 1),
                            isActive: true
                        };
                    }
                }

                if (d2) {
                    if (this.isToday(d2)) {
                        daysArray[1] = {
                            day: "",
                            date: "",
                            isActive: false,
                        };
                    }

                    d2.setDate(d2.getDate() - 1);
                    daysArray[2] = {
                        day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(new Date(d2)),
                        date: d2.getDate() + "." + (d2.getMonth() + 1),
                        isActive: d2.getDate() + "." + (d2.getMonth() + 1) === now.getDate() + "." + (now.getMonth() + 1),
                    };

                    if (this.isToday(d2)) {
                        daysArray[1] = {
                            day: "",
                            date: "",
                            isActive: false,
                        };
                    }
                }
            } else {
                if (d2) {
                    daysArray[0] = {
                        day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(new Date(d2)),
                        date: d2.getDate() + "." + (d2.getMonth() + 1),
                        isActive: d2.getDate() + "." + (d2.getMonth() + 1) === now.getDate() + "." + (now.getMonth() + 1),
                    };

                    let nextFriday = this.nextFriday(d2);

                    daysArray[1] = {
                        day: new Intl.DateTimeFormat("ru-RU", {weekday: "short",}).format(new Date(nextFriday)),
                        date: nextFriday.getDate() + "." + (nextFriday.getMonth() + 1),
                        isActive: false,
                    };
                }
            }

            return daysArray;
        },

        isToday(date) {
            const today = new Date();
            return date.getDate() == today.getDate() &&
                date.getMonth() == today.getMonth() &&
                date.getFullYear() == today.getFullYear();
        },

        nextFriday(date) {
            let d = new Date(date);

            d.setDate(d.getDate() + 1);

            switch (d.getDay()) {
                case 0:
                    d.setDate(d.getDate() + 5);
                    break;

                case 1:
                    d.setDate(d.getDate() + 4);
                    break;

                case 2:
                    d.setDate(d.getDate() + 3);
                    break;

                case 3:
                    d.setDate(d.getDate() + 2);
                    break;

                case 4:
                    d.setDate(d.getDate() + 1);
                    break;

                case 6:
                    d.setDate(d.getDate() + 6);
                    break;
            }

            d.setDate(d.getDate() - 1);

            return d;
        },

        getNextFridayDate() {
            const now = new Date();
            let fridayDate = new Date();
            let friday = 5 - now.getDay();
            fridayDate.setDate(fridayDate.getDate() + friday);
            fridayDate.setHours(18, 0, 0, 0);

            if (now > fridayDate) {
                const nextMonday = new Date();
                nextMonday.setDate(
                    nextMonday.getDate() + ((7 - nextMonday.getDay()) % 7) + 1
                );
                const nextFriday = this.nextWeekdayDate(nextMonday, 5);
                nextFriday.setHours(18, 0, 0, 0);

                fridayDate = nextFriday;
            }

            return fridayDate;
        },

        nextWeekdayDate(date, day_in_week) {
            const ret = new Date(date || new Date());
            ret.setDate(ret.getDate() + ((day_in_week - 1 - ret.getDay() + 7) % 7) + 1);
            return ret;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "studyCalendar.scss";
</style>
