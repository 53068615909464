<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--3 study">
                <div class="studyPageHeader">
                    <b-card v-if="isLoadingWeekPlanData" style="width: 50%">
                        <div class="studyPageHeader__timerSection">
                            <b-skeleton width="80%"></b-skeleton>
                            <b-skeleton
                                width="40%"
                                type="input"
                                style="margin-left: 12px"
                            ></b-skeleton>
                        </div>
                        <b-skeleton width="60%" style="margin-top: 10px"></b-skeleton>
                    </b-card>
                    <div v-else>
                        <div class="studyPageHeader__timerSection">
                            <p class="h4 studyPageHeader__timerSection_title">
                                {{ this.weekPlanTitleString }}
                            </p>
                            <TextTimer
                                v-if="
                                    $store.getters.getWeekPlan &&
                                    $store.getters.getWeekPlan.currentBlock &&
                                    $store.getters.getWeekPlan.currentBlock.openDate
                                        ? $store.getters.getWeekPlan.currentBlock.openDate
                                        : $store.getters.getWeekPlan &&
                                          $store.getters.getWeekPlan.nextBlock &&
                                          $store.getters.getWeekPlan.nextBlock.openDate
                                        ? $store.getters.getWeekPlan.nextBlock.openDate
                                        : false
                                "
                                :style="this.timerStyle"
                                :time="
                                    $store.getters.getWeekPlan &&
                                    $store.getters.getWeekPlan.currentBlock &&
                                    $store.getters.getWeekPlan.currentBlock.openDate
                                        ? $store.getters.getWeekPlan.currentBlock.openDate
                                        : $store.getters.getWeekPlan.nextBlock.openDate
                                "
                            />
                        </div>
                        <p class="text2 colorFiord">
                            {{ this.weekPlanDescriptionString }}
                        </p>
                    </div>
                    <div class="studyPageHeader__buttonSection">
                        <!--<Button
                            title="Уведомить, когда будет старт"
                            :onClick="() => onConfirmConfirmation()"
                        />-->
                        <b-skeleton
                            v-if="isLoadingWeekPlanData"
                            width="44px"
                            height="44px"
                            type="button"
                        ></b-skeleton>
                        <button
                            v-else
                            class="studyPageHeader__schedule"
                            @click="() => onOpenModal()"
                        >
                            <img
                                alt="square"
                                class="imgBlock"
                                src="@/assets/img/pages/study/square.svg"
                            />
                        </button>
                        <StudyCalendar
                            v-if="isVisibleModal"
                            :onClose="() => onCloseModal()"
                        />
                    </div>
                </div>

                <div class="pageTitle pageTitleTimer">
                    <p class="pageTitleTimer__title">
                        Блоки обучения
                    </p>

                    <div v-if="courseEnded" class="courseEndedText courseEndedBox">
                        <div class="courseEnded">
                            <p class="h4">До конца обучения:</p>
                            <p class="courseEndedTime title1 title1--2">
                                <b>{{ courseEnded }}</b>
                                <span v-if="courseEnded === 1"> день</span>
                                <span v-else-if="courseEnded > 1 && courseEnded < 5">
                                    дня</span
                                >
                                <span v-else> дней</span>
                            </p>
                        </div>

                        <StudyInfoProtector
                            v-if="isVisibleModalInfoProtector"
                            :onClose="() => onCloseModalInfoProtector()"
                        />
                    </div>
                </div>

                <div>
                    <div
                        v-if="
                            $store.getters.getUserProgress &&
                                $store.getters.getUserProgress.progress &&
                                $store.getters.getUserProgress.progress.data &&
                                $store.getters.getUserProgress.additionalProgress &&
                                $store.getters.getUserProgress.additionalProgress.data
                        "
                        class="studyContentHeader"
                    >
                        <div class="studyButtons">
                            <Button
                                :extraClass="
                                    main
                                        ? `button--small`
                                        : `button--small button--smallWhite`
                                "
                                :onClick="() => handleClickRadioButton('MAIN')"
                                class="studyButtons__button"
                                title="Основные"
                            />
                            <Button
                                :extraClass="
                                    additional
                                        ? `button--small`
                                        : `button--small button--smallWhite`
                                "
                                :onClick="() => handleClickRadioButton('ADDITIONAL')"
                                class="studyButtons__button"
                                title="Дополнительные"
                            />
                        </div>
                    </div>

                    <div
                        v-if="
                            $store.getters.getUserProgress &&
                                $store.getters.getUserProgress.progress &&
                                $store.getters.getUserProgress.progress.data &&
                                $store.getters.getUserProgress.progress.data[0] &&
                                $store.getters.getUserProgress.progress.data[0].blocks
                        "
                        :class="radioButton ? 'hidden' : ''"
                    >
                        <div
                            class="popup-layout"
                            v-if="
                                isVisibleModalStudyOnboarding ||
                                    this.$store.getters.getIsReviewOnboardStudy
                                        .isReviewOnboardStudy
                            "
                        ></div>

                        <OnboardModal
                            style="left: 288px; top: 376px;"
                            v-if="
                                isVisibleModalStudyOnboarding ||
                                    this.$store.getters.getIsReviewOnboardStudy
                                        .isReviewOnboardStudy
                            "
                            currentStep="1"
                            totalSteps="1"
                            title="Открытие блоков"
                            description="Основне блоки с уроками открываются каждую пятницу в 18:00 по МСК (UTC+3).
                            Если видео не открывается, зайдите на урок несколько раз. "
                            :isNextButton="true"
                            nextButtonTitle="ЗАВЕРШИТЬ"
                            :onClose="() => showedStudyOnboarding()"
                            :onCloseModal="() => showedStudyOnboarding()"
                        />
                        <div
                            :class="
                                ` ${
                                    isVisibleModalStudyOnboarding ||
                                    this.$store.getters.getIsReviewOnboardStudy
                                        .isReviewOnboardStudy
                                        ? 'studyBlockOnboarding'
                                        : ''
                                }`
                            "
                        >
                            <b-card v-if="isLoadingStudyData">
                                <b-skeleton height="96px"></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                                <b-skeleton
                                    style="margin-top: 10px"
                                    height="96px"
                                ></b-skeleton>
                            </b-card>
                            <div
                                v-else
                                v-for="(item, index) in $store.getters.getUserProgress
                                    .progress.data[0].blocks"
                                :key="item.block_number"
                                class="study__contentItem"
                            >
                                <StudyCard
                                    :hasBlockAccess="item.hasAccesByAccesLevel"
                                    :isLocked="!item.opened"
                                    :studyCardLessons="item.lessons"
                                    :studyCardNumber="item.blockPriority"
                                    :studyCardTitle="item.blockTitle"
                                    :studyCourse="member.slug"
                                    :studyFinishedLessons="
                                        item.lessons.filter((i) => i.isPassed).length
                                    "
                                    :studyIcon="
                                        require(`@/assets/img/pages/study/arrowRight.svg`)
                                    "
                                    :studyTotalLessons="item.blockCountLessons"
                                    :lockedBlockText="
                                        index === 0 &&
                                        $store.getters.getUserProgress.progress.data[0]
                                            .blocks[0] &&
                                        !$store.getters.getUserProgress.progress.data[0]
                                            .blocks[0].opened
                                            ? 'Этот блок обучения пока не доступен Вам. Он будет доступен в ближайшую пятницу в 12:00 по МСК (UTC+3).'
                                            : 'Этот блок обучения пока недоступен Вам. Он будет доступен в ближайшую пятницу в 18:00 по МСК (UTC+3) после просмотра всех видео-уроков и прохождения заданий предыдущего блока обучения.'
                                    "
                                    :member="member"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        $store.getters.getUserProgress &&
                            $store.getters.getUserProgress.additionalProgress &&
                            $store.getters.getUserProgress.additionalProgress.data &&
                            $store.getters.getUserProgress.additionalProgress.data[0] &&
                            $store.getters.getUserProgress.additionalProgress.data[0]
                                .blocks
                    "
                    :class="radioButton ? '' : 'hidden'"
                >
                    <div
                        v-for="(item, index) in $store.getters.getUserProgress
                            .additionalProgress.data[0].blocks"
                        :key="item.block_number"
                        class="study__contentItem"
                    >
                        <StudyCard
                            :isLocked="!item.hasAccesByAccesLevel"
                            :studyCardLessons="item.lessons"
                            :studyCardNumber="item.blockPriority"
                            :studyCardTitle="item.blockTitle"
                            :studyCourse="member.slug"
                            :studyFinishedLessons="item.blockProgress"
                            :studyIcon="
                                require(`@/assets/img/pages/study/arrowRight.svg`)
                            "
                            :studyTotalLessons="item.blockCountLessons"
                            :lockedBlockText="
                                !item.opened &&
                                $store.getters.getUserProgress.additionalProgress.data[0]
                                    .blocks[index - 1].opened
                                    ? 'Этот блок обучения пока недоступен Вам. Он будет доступен в ближайшую пятницу в 18:00 по МСК (UTC+3).'
                                    : 'Этот блок обучения пока недоступен Вам. Он будет доступен после просмотра видео-уроков и прохождения заданий предыдущего блока обучения.'
                            "
                            :member="member"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import Button from "@/views/components/Button/Button";
import TextTimer from "@/views/components/TextTimer/TextTimer";
import StudyCard from "@/views/components/StudyCard/StudyCard";
import StudyCalendar from "@/views/pages/study/studyCalendar/studyCalendar";
import StudyInfoProtector from "@/views/pages/study/studyInfoProtector/studyInfoProtector";

import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

export default {
    name: "study",
    components: {
        Layout,
        SideBar,
        Button,
        TextTimer,
        StudyCard,
        StudyCalendar,
        StudyInfoProtector,
        OnboardModal,
    },

    async mounted() {
        // await this.updateProgressStore();
        // await this.updateAdditionalProgressStore();

        this.renderPageData();
    },

    async beforeMount() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.study
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug);
        }

        // await this.updateMemberStore();
        // await this.updateMemberConfirmationsStore();

        await this.updateWeekPlanData();
    },

    watch: {
        "$route.params.slug": function() {
            // this.updateProgressStore();
            // this.updateAdditionalProgressStore();
            // this.updateMemberStore();
            // this.updateMemberConfirmationsStore();
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        courseEnded() {
            return this.member.to_the_end || 0;
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        isVisibleModalStudyOnboarding: function() {
            return (
                // this.isLoadedConfirmations &&
                this.confirmations &&
                this.confirmations.viewedWelcomePopup &&
                !(this.member.agreementData && this.member.agreementData.showRules) &&
                this.confirmations.viewedWelcomeVideo &&
                !this.confirmations.viewedOnBoardingStudy &&
                this.$route.path === "/trejding_ot_a_do_ya_3_5/study"
            );
        },
    },

    data() {
        return {
            title: "Обучение",
            weekPlanTitleString: "",
            weekPlanDescriptionString: "",
            timerStyle: "display: block;",
            isVisibleModal: false,
            isVisibleModalInfoProtector: false,
            radioButton: false,
            main: true,
            additional: false,
            isLoadedConfirmations: false,

            isLoadingStudyData: false,
            isLoadingWeekPlanData: false,

            isReviewOnboardStudy: this.$store.getters.getIsReviewOnboardStudy
                .isReviewOnboardStudy,
        };
    },

    methods: {
        onOpenModal() {
            this.isVisibleModal = true;
        },
        onCloseModal() {
            this.isVisibleModal = false;
        },

        onOpenModalInfoProtector() {
            this.isVisibleModalInfoProtector = true;
        },
        onCloseModalInfoProtector() {
            this.isVisibleModalInfoProtector = false;
        },

        handleClickRadioButton(value) {
            if (value === "MAIN") {
                this.main = true;
                this.additional = false;
                this.radioButton = false;
            }

            if (value === "ADDITIONAL") {
                this.main = false;
                this.additional = true;
                this.radioButton = true;
            }
        },

        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (
                    getMemberData.data.code === "programs" ||
                    getMemberData.data.code === "banned" ||
                    getMemberData.data.code === "blocked"
                ) {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async showedStudyOnboarding() {
            if (
                this.isVisibleModalStudyOnboarding &&
                this.$store.getters.getIsReviewOnboardStudy.isReviewOnboardStudy
            ) {
                if (
                    this.$store.getters.getUserProgress.progress.data[0].blocks[0].opened
                ) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingStudy"
                    );
                    await this.updateMemberStore();
                    await this.updateMemberConfirmationsStore();
                    await this.$store.dispatch("setIsReviewOnBoardStudy", false);
                    await this.$router.push(
                        "/" + this.$route.params.slug + "/study/1/604"
                    );
                    this.$store.dispatch("setIsReviewOnBoardLesson", true);
                } else {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingStudy"
                    );
                    await this.updateMemberStore();
                    await this.updateMemberConfirmationsStore();
                    await this.$store.dispatch("setIsReviewOnBoardStudy", false);
                    await this.$router.push("/" + this.$route.params.slug + "/webinars");
                    await this.$store.dispatch("setIsReviewOnBoardWebinars", true);
                }
            } else {
                if (this.isVisibleModalStudyOnboarding) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingStudy"
                    );
                    await this.updateMemberStore();
                    await this.updateMemberConfirmationsStore();
                } else {
                    if (
                        this.$store.getters.getUserProgress.progress.data[0].blocks[0]
                            .opened
                    ) {
                        await this.$store.dispatch("setIsReviewOnBoardStudy", false);
                        await this.$router.push(
                            "/" + this.$route.params.slug + "/study/1/604"
                        );
                        await this.$store.dispatch("setIsReviewOnBoardLesson", true);
                    } else {
                        await this.$store.dispatch("setIsReviewOnBoardStudy", false);
                        await this.$router.push(
                            "/" + this.$route.params.slug + "/webinars"
                        );
                        await this.$store.dispatch("setIsReviewOnBoardWebinars", true);
                    }
                }
            }
        },

        async onConfirmConfirmation() {
            await this.$store.dispatch("setAlert", {
                alert: {
                    isActive: true,
                    type: "success",
                    text:
                        "Мы отправим Вам на почту письмо с напоминанием о старте обучения",
                },
            });
        },

        async updateProgressStore() {
            this.isLoadingStudyData = true;
            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске основных блоков: " + getProgress.data.error
                );
                await this.$store.dispatch("clearUserProgress");
            }

            this.isLoadingStudyData = false;
        },

        async updateAdditionalProgressStore() {
            const getAdditionalProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress/additional"
            );

            if (
                getAdditionalProgress &&
                getAdditionalProgress.data &&
                getAdditionalProgress.data.result
            ) {
                await this.$store.dispatch(
                    "setUserAdditionalProgress",
                    getAdditionalProgress.data
                );
            } else {
                console.error(
                    "Ошибка при поиске дополнительных блоков: " +
                        (getAdditionalProgress.data.error || "")
                );
                await this.$store.dispatch("clearUserAdditionalProgress");
            }
        },

        updateWeekPlanData: async function() {
            this.isLoadingWeekPlanData = true;
            const getWeekPlanDataResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/week_plan"
            );
            if (
                getWeekPlanDataResult &&
                getWeekPlanDataResult.data &&
                getWeekPlanDataResult.data.result
            ) {
                await this.$store.dispatch("setWeekPlan", getWeekPlanDataResult.data);
            } else {
                await this.$store.dispatch("clearWeekPlan");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке недельного плана",
                    },
                });
            }
            this.isLoadingWeekPlanData = false;
        },

        renderPageData: function() {
            const weekPlan = this.$store.getters.getWeekPlan;

            const currentBlockOpenDate =
                weekPlan && weekPlan.currentBlock && weekPlan.currentBlock.openDate
                    ? new Date(weekPlan.currentBlock.openDate)
                    : null;

            if (currentBlockOpenDate) {
                this.weekPlanTitleString = `${weekPlan.currentBlock.description}. `;
                this.weekPlanTitleString += `Блок ${weekPlan.currentBlock.title} будет открыт через `;
                this.timerStyle = "display: block;";
            } else {
                if (weekPlan.nextBlock && weekPlan.nextBlock._id) {
                    this.weekPlanTitleString = `Следующий блок ${weekPlan.nextBlock.title}`;
                }
                if (
                    weekPlan &&
                    weekPlan.currentBlock &&
                    weekPlan.currentBlock.priority &&
                    weekPlan.currentLesson &&
                    weekPlan.currentLesson.lesson &&
                    weekPlan.currentLesson.lesson.title
                ) {
                    this.weekPlanDescriptionString = `Текущее задание: Блок ${weekPlan.currentBlock.priority}. Урок ${weekPlan.currentLesson.priority}. ${weekPlan.currentLesson.lesson.title}`;
                }
                this.timerStyle = "display: block;";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "study.scss";
</style>
